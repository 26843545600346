<template>
  <div class="sliderBar">
    <ul>
      <li
        v-for="(item, i) in navList"
        :key="i"
        :class="{ active: isActive(item) }"
        v-show="!item.isSubMenu"
        @click="handleClick(item)"
      >
        {{ item.name }}
      </li>
    </ul>
  </div>
</template>

<script>
import { menuList } from "@/router/index";

export default {
  data() {
    return {
      menuList,
    };
  },
  props: {
    showInvoices: {
      type: Boolean,
      default: false,
    },
  },
  computed: {

    curMenuKey() {
      return this.$route.meta.key;
    },
    navList() {
      console.log("----", this.showInvoices);
      return this.showInvoices
        ? menuList
        : menuList.filter((item) => item.path !== "/userInvoices" && item);
    },
  },
  methods: {
    handleClick(item) {
      this.$router.push(item.path);
    },
    isActive(item) {
      return this.curMenuKey === item.meta.key;
    },
  },
};
</script>

<style lang="scss" scoped>
.sliderBar {
  width: 226px;
  background-color: #fff;
  ul {
    li {
      height: 55px;
      line-height: 55px;
      padding-left: 35px;
      font-weight: bold;
      position: relative;
      cursor: pointer;
      &.active {
        color: #3377ff;
      }
      &.active::before {
        content: "";
        width: 3px;
        height: 31px;
        background-color: #3377ff;
        position: absolute;
        left: 0;
        top: 12px;
      }
    }
  }
}
</style>
