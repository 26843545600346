/* eslint-disable */
import request from '@/utils/request';
//获取付费课列表
export function queryUsersCourses(payload) {
  return request('/hcp/yida/chargeCourse/list', {
    method: 'get',
    params: payload,
  });
}
//获取课程详情
export function queryCourseDetail(payload) {
  return request('/hcp/yida/chargeCourse/detail', {
    method: 'get',
    params: payload,
  });
}
//获取章节课表
export function querySections(payload) {
  return request('/hcp/yida/chargeCourse/curriculum', {
    method: 'get',
    params: payload,
  });
}

//用户进入直播间
export function userJoinLiveCourse(payload) {
  return request('/hcp/yida/chargeCourse/joinLive', {
    method: 'get',
    params: payload,
  });
}

//获取章节详情
export function querySectionDetail(payload) {
  return request("/hcp/yida/chargeCourse/sectionDetails", {
    method: 'get',
    params: payload,
  })
}

export function queryGroupList(payload) {
  return request("/hcp/yida/chargeCourse/groupNumberList", {
    method: 'get',
    params: payload,
  })
}


export function queryChapterType(payload) {
  return request("/hcp/base/base/dict/ChapterType", {
    method: "get"
  })

}

//获取章节类型排序序号

export function getChaptersSort(payload) {
  return request("/hcp/yida/chargeCourse/getCourseTypeSort", {
    method: "get",
    params: payload,
  })
}


//获取学习卡剩余选课科数
export function getClassCount() {
  return request("/hcp/yida/sc/quantity", { method: "get" })
}


//获取学习卡产品可选课程列表
export function getStudyCardCourses(payload) {
  return request("/hcp/yida/sc/csItemList", { method: "get", params: payload })
}

//确认选课
export function checkStudyCardCourse(payload) {
  return request("/hcp/yida/sc/choice", { method: "get", params: payload })
}
//学习卡商品
export function getStudyCardGoods() {
  return request("/hcp/yida/sc/itemList", { method: "get" })
}

//录播课日志
export function logReplayCourse(payload) {
  // courseDetailId: this.courseDetailId,
  return request('/hcp/yida/chargeCourse/alog', { method: "get", params: payload })
}

//领取资料
export function receiveMaterial(payload) {
  return request('/hcp/yida/chargeCourse/receiveMaterial', { method: "post", data: payload })
}
//查看资料
export function viewMaterial(payload) {
  return request('/hcp/yida/chargeCourse/getMaterial', { method: "post", data: payload })
}