/* eslint-disable */
import request from '@/utils/request';

//获取用户信息
export function queryUserInfo() {
  return request('/hcp/user/info/getYsgUserPersonalInfo', {
    method: 'get',
  });
}

//用户退出登录
export function userLoginOutFromWebsite(payload) {
  return request('/hcp/user/sso/logout', {
    method: 'get',
    params: payload,
  });
}

//获取用户考试信息
export function getUserExamInfo(payload) {
  return request('/hcp/user/info/getUserExamInfo', {
    method: 'get',
    params: payload,
  });
}

// 修改用户信息
export function editUserInfo(payload) {
  return request('/hcp/user/info/updateSimpleUserInfo', {
    method: 'post',
    data: payload,
  });
}

// 修改用户考试信息
export function editUserExamInfo(payload) {
  return request('/hcp/user/info/updateUserExamInfo', {
    method: 'post',
    data: payload,
  });
}

export function fetchExamDates(payload) {
  return request('/hcp/base/examTime/thisMon', {
    method: 'get',
    params: payload,
  });
}

// 修改密码
export function editPassword(payload) {
  return request('/hcp/user/info/newUpdatePassword', {
    method: 'post',
    data: payload,
  });
}


export function getPublicAccountQRCode(payload) {
  return request("/hcp/base/wxMiniApp/gmcurl", { method: "get", params: payload })
}

export function checkFollowedPubAccount() {
  return request('/hcp/base/wxofficialbinding/officialBindingStatus', { method: "get" })
}
/**
 * 
 * @returns 
 */
export function checkShowWxPubAccount() {
  return request("/hcp/base/base/config/showWechatPublicAccount", { method: "get" })
}