/* eslint-disable */
import http from "@/utils/request";
// import { formatGoods } from '@/utils/format';
import sortClassList from "@/utils/sortClassList";

export default {
  namespaced: true,
  state: {
    bannerList: [],
    hotGoodsList: [],
    goodsList: []
  },
  mutations: {
    UPDATE_VALUE_STATES(state, payload) {
      const { key, data } = payload;
      state[key] = data;
    }
  },
  actions: {
    // 获取首页banner
    async getBanner({ dispatch, commit }) {
      const res = (await http(`/hcp/base/base/dict/YidaAdBanner`)) || [];
      commit("UPDATE_VALUE_STATES", { key: "bannerList", data: res });
    },
    // 获取热门精选商品
    async getHotGoods({ dispatch, commit }) {
      const res = (await http(`/hcp/trade/item/getHotItems`)) || [];
      const list = res.filter(item => [1, 2, 3, 4].includes(item.type)) || [];
      // list.map(it => formatGoods(it))
      commit("UPDATE_VALUE_STATES", { key: "hotGoodsList", data: list });
    },
    // 获取专区商品
    async getGoods({ dispatch, commit }, payload) {
      const res = (await http(`/hcp/trade/item/getByType`, { params: { ...payload } })) || [];
      const list = res.filter(item => [1, 2, 3, 4].includes(item.type)) || [];
      // list.map(it => formatGoods(it))
      if (payload.type === 1) {
        commit("UPDATE_VALUE_STATES", { key: "goodsList", data: sortClassList(list) });
      } else {
        commit("UPDATE_VALUE_STATES", { key: "goodsList", data: list });
      }
    }
  }
};
