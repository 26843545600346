import { localStorageUtils } from '@/utils/storage';

export function getSearchToken() {
  const urlObj = new URL(window.location.href);
  return urlObj.searchParams.get('token') || '';
}

export function getLocalToken() {
  return localStorageUtils.getItem('token');
}

export function setLocalToken(token) {
  localStorageUtils.setItem('token', token);
}

export function deleteTokenPath() {
  const urlObj = new URL(window.location.href);
  urlObj.searchParams.delete('loginToken');
  return urlObj.pathname + urlObj.search;
}

export function getUserInfo() {
  return localStorageUtils.getItem('userInfo');
}
