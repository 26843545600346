<template>
  <div id="app" v-if="ssoFinish" class="entry_app_container">
    <el-dialog
      title="访问提示"
      v-model="isIEBrowser"
      width="35%"
      :center="true">
      <p class="title">当前浏览器性能较低，推荐您使用谷歌Chrome浏览器访问本网站。</p>
<!--      <p class="url" @click="saveUrl">{{curUrl}}</p>-->
<!--      <p class="text">（点击可复制网址）</p>-->
    </el-dialog>
    <router-view v-if="isRouteAlive"></router-view>
  </div>
</template>
<script>
import index from '@/utils/useSso/index';
import { checkIsIEBrowser } from '@/utils/wyUtil';

export default {
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      isIEBrowser: checkIsIEBrowser(),
      curUrl: `${window.location.origin}/home`,
      isRouteAlive: true // 是否重新加载页面
    };
  },
  setup() {
    const { ssoFinish } = index();
    return {
      ssoFinish,
    };
  },
  methods: {
    saveUrl() {
      const url = `${window.location.origin}/home`;
      const input = document.createElement('input');
      input.setAttribute('readonly', 'readonly');
      input.setAttribute('type', 'text');
      input.setAttribute('value', url);
      document.body.appendChild(input);
      input.select();
      if (document.execCommand('copy')) {
        document.execCommand('copy');
        console.log('复制成功');
        this.$message({ message: '复制成功', type: 'success' });
      }
      document.body.removeChild(input);
    },
    reload() {
      this.isRouteAlive = false
      this.$nextTick(function() {
        this.isRouteAlive = true
      })
    }
  },
};
</script>
<style lang="scss" scoped>
  .entry_app_container{
    .title{
      color:#888;
      line-height: 30px;
      font-size: 16px;
      text-align-last: center;
    }
    .text{
      color:#999;
      font-size: 12px;
      line-height: 30px;
      text-align: center;
    }
    .url{
      line-height: 30px;
      font-size: 12px;
      color:#3377FF;
      text-align: center;
      cursor: pointer;
    }

  }

</style>
