/* eslint-disable */
import {
  queryUsersCourses, queryCourseDetail, querySections, userJoinLiveCourse,
  querySectionDetail, queryGroupList, queryChapterType, getChaptersSort, getClassCount, getStudyCardCourses,
  checkStudyCardCourse, getStudyCardGoods, receiveMaterial, viewMaterial
} from '@/services/course';
import { localStorageUtils } from '../../utils/storage';


export default {
  namespaced: true,
  state: {
    userCourseList: [],
    courseDetail: {}, // 课程详情
    sectionData: [], // 章节列表
    liveAccessToken: '',
    sectionDetail: {}, // 章节详情
    groupsData: {},
    chapterTypes: [],//章节类型
    chaptersSort: null,//章节排序
    restClassCount: 0,//可选课科数
    scCourseList: [],
    scGoodsList: [],//已购的学习卡商品
    materialData: {} // 配置资料
  },
  actions: {
    async getChaptersSort({ dispatch, commit, getters, rootGetters }, payload) {
      const content = await getChaptersSort(payload)
      commit("UPDATE_STATE_VALUE", { key: 'chaptersSort', data: content || {} })
    },
    async fetchUserCourses({ commit }, payload) {
      if (!!localStorage.getItem('token')) {
        const content = await queryUsersCourses(payload);
        commit('UPDATE_STATE_VALUE', { key: 'userCourseList', data: content });
      }
    },
    async fetchCourseDetail({ commit }, payload) {
      const content = await queryCourseDetail(payload);
      commit('UPDATE_STATE_VALUE', { key: 'courseDetail', data: content });
    },
    async fetchSections({ commit }, payload) {
      const content = await querySections(payload);
      commit('UPDATE_STATE_VALUE', { key: 'sectionData', data: content });
    },
    async liveCourseJoin({ commit }, payload) {
      const content = await userJoinLiveCourse(payload);
      commit('UPDATE_STATE_VALUE', { key: 'liveAccessToken', data: content });
    },
    async fetchSectionDetail({ commit }, payload) {
      const content = await querySectionDetail(payload);
      commit('UPDATE_STATE_VALUE', { key: 'sectionDetail', data: content });
    },
    async fetchGroupList({ commit }, payload) {
      const content = await queryGroupList(payload);
      commit('UPDATE_STATE_VALUE', { key: 'groupsData', data: content || {} });
    },
    async fetchChapterTypes({ commit }, payload) {
      const content = await queryChapterType(payload);
      commit('UPDATE_STATE_VALUE', { key: 'chapterTypes', data: content || [] });
    },
    async getRestClassCourse({ dispatch, commit, getters, rootGetters }, payload) {
      if (!!localStorage.getItem('token')) {
        const content = await getClassCount()
        commit('UPDATE_STATE_VALUE', { key: 'restClassCount', data: content || 0 })
        localStorageUtils.setItem("restClassCount", content || 0)
      }
    },
    async fetchStudyCardCourses({ dispatch, commit }, payload) {
      const content = await getStudyCardCourses(payload)
      commit('UPDATE_STATE_VALUE', { key: "scCourseList", data: content || [] })
    },
    async checkCourse({ dispatch, commit }, payload) {
      const { callback, chargeCourseId, goodsId } = payload || {}
      await checkStudyCardCourse({ chargeCourseId, itemId: goodsId })
      dispatch("getScGoods", { goodsId: goodsId })
      typeof callback === "function" && callback()
    },
    async getScGoods({ dispatch, commit }, payload) {
      const content = await getStudyCardGoods()
      if (Array.isArray(content) && content.length ) {
        const {goodsId}=payload;
        let hasTarget=goodsId?content.find(item=>item.itemId===`${goodsId}`&&item):false;
        dispatch("fetchStudyCardCourses", { itemId: hasTarget?goodsId:content[0].itemId })
      }
      commit('UPDATE_STATE_VALUE', { key: "scGoodsList", data: content || [] })
    },
    async receiveMaterial({ commit }, payload) {
      const content = await receiveMaterial(payload);
      commit('UPDATE_STATE_VALUE', { key: 'materialData', data: {receive: true, content} });
    },
    async viewMaterial({ commit }, payload) {
      const content = await viewMaterial(payload);
      commit('UPDATE_STATE_VALUE', { key: 'materialData', data: {receive: false, content} });
    },
    
  },
  mutations: {
    UPDATE_STATE_VALUE(state, payload) {
      const { key, data } = payload || {};
      state[key] = data;
    },

  },
};
