/* eslint-disable */

export default {
  namespaced: true,
  state: {
    currentNavKey:"home"

  },
  actions: {

  },
  mutations: {
    UPDATE_STATE_VALUE(state, payload) {
      const { key, data } = payload || {};
      state[key] = data;
    },
  },
};
