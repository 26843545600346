/* eslint-disable */
import request from '@/utils/request';


//获取用户订单列表信息
// orderPayStatus (integer, optional): 订单支付状态 0:未支付 ,1:已支付 ,2:已取消 ,3:支付失败 ,查全部的时候不传
export function queryUserOrderList(payload) {
  return request('/hcp/trade/customer/page', {
    method: 'post',
    data: payload,
  })
}

//取消支付
export function cancelOrder(payload){
  return request('/hcp/trade/customer/cancelOrder?orderCode='+payload.orderCode, {
    method: 'post',
  })
}

//获取订单详情
export function queryOrderDetail(payload){
  return request('/hcp/trade/customer/detail?orderCode='+payload.orderCode, {
    method: 'post',
  })
}

//获取支付签名
export function queryPaySign(payload){
  return request('/hcp/trade/h5/unifiedOrder', {
    method: 'post',
    data:payload,
  })
}

// 新增评论
export function addComment(data) {
  return request('/hcp/user/comment/addComment',{
    method: 'post',
    data,
  })
}

// 获取我的发票列表
export function  queryInvoiceList(data) {
  return request('/hcp/trade/order/invoice/myOrderInvoice',{
    method: 'get',
    params:data,
  })
}