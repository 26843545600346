/* eslint-disable */
import {
  queryUsersOralRecords,
  queryRestOralCount,
  queryBookCourseList,
  userBookCourse,
  userCancelBookCourse,
  evaluateCourse,
  checkBindTeacher,
  getOralAssistant,
  getNotForClass,
  getOralDetail
} from "@/services/oral";
import { getPublicAccountQRCode, checkFollowedPubAccount, checkShowWxPubAccount } from "@/services/user";

export default {
  namespaced: true,
  state: {
    oralRecords: {},
    oralRecordsList: [],
    oralCount: 0,
    bookCourseData: [],
    hasBindTeacher: false,
    oralAssistant: "",
    bindTeachers: [],
    classNotes: [],
    oralDetail: [],
    targetScore: "", // 当前计划目标分数
    wxPubAccountQrcode: "",
    hasFollowedPub: false
  },
  actions: {
    async userOralRecords({ commit, state }, payload) {
      if (payload.curPage === 1) {
        commit("UPDATE_STATE_VALUE", { key: "oralRecordsList", data: [] });
      }
      const content = await queryUsersOralRecords(payload);
      // console.log(content.list || [], '什么垃圾')
      commit("UPDATE_STATE_VALUE", { key: "oralRecords", data: content });
      commit("UPDATE_STATE_VALUE", {
        key: "oralRecordsList",
        data: (state.oralRecordsList || []).concat(content.list || [])
      });
    },
    async fetchOralCount({ commit }) {
      const content = await queryRestOralCount();
      commit("UPDATE_STATE_VALUE", { key: "oralCount", data: content });
    },
    async queryBookCourseList({ commit }, payload) {
      const content = await queryBookCourseList(payload);
      let arr = [];
      Array.isArray(content) &&
        content.forEach(item => {
          arr = [...arr, ...(item.courses || []).map(e => ({ ...e, teacherPic: item.teacherPic }))];
        });
      commit("UPDATE_STATE_VALUE", { key: "bookCourseData", data: arr });
    },
    async bookedCourse({ commit }, payload) {
      const { callback, ...rest } = payload;
      await userBookCourse(rest);
      typeof callback === "function" && callback();
    },
    async cancelBookedCourse({ commit }, payload) {
      const { callback, ...rest } = payload;
      await userCancelBookCourse(rest);
      typeof callback === "function" && callback();
    },
    async addCourseComment({ commit }, payload) {
      const { callback, ...rest } = payload;
      await evaluateCourse(rest);
      typeof callback === "function" && callback();
    },
    async getBindTeachers({ dispatch, commit }) {
      const content = await checkBindTeacher();
      if (!content.length) {
        dispatch("getAssistant");
      } else {
        commit("UPDATE_STATE_VALUE", { key: "bindTeachers", data: content });
      }
      commit("UPDATE_STATE_VALUE", { key: "hasBindTeacher", data: !!content.length });
    },
    async getAssistant({ commit }, payload) {
      const content = await getOralAssistant(payload);
      commit("UPDATE_STATE_VALUE", { key: "oralAssistant", data: content });
    },
    async getDetail({ commit }, payload) {
      const content = await getOralDetail(payload);
      commit("UPDATE_STATE_VALUE", { key: "oralDetail", data: content });
    },
    async getClassNotes({ commit }) {
      const content = await getNotForClass();
      commit("UPDATE_STATE_VALUE", { key: "classNotes", data: content });
    },
    async createPubAccount({ commit }, payload) {
      const content = await getPublicAccountQRCode(payload);
      commit("UPDATE_STATE_VALUE", { key: "wxPubAccountQrcode", data: content });
    },
    async checkFollowStatus({ commit }) {
      const content = await checkFollowedPubAccount();
      commit("UPDATE_STATE_VALUE", { key: "hasFollowedPub", data: content });
      return content;
    },
    async checkShowPubParams({ commit }) {
      const content = await checkShowWxPubAccount()
      return content
    },
    async canShowPubAccount({ dispatch }) {
      let flag = false
      // const res =await dispatch("checkShowPubParams")
      // if (res === "0") { return flag }
      const followed =await dispatch('checkFollowStatus')
      flag = !followed;
      return flag
    }
  },
  mutations: {
    UPDATE_STATE_VALUE(state, payload) {
      const { key, data } = payload || {};
      state[key] = data;
    },
    UPDATE_ASSISTANTS(state, payload) {
      let arr = [...state.assistants, payload];
      state.assistants = arr.slice(0, 2);
    }
  }
};
