import router from '@/router';
import { clearQueryString, clearLoginTokenString } from '@/utils/useSso/clearQueryString';
import { locationUtils } from '@/utils/useSso/locationUtils';
import { localStorageUtils } from '@/utils/useSso/localStorageUtils';
import { ref } from 'vue';
import store from "../../store"
import request from '@/utils/request';


function getInvoices() {
  store.dispatch("order/getInvoicesData", {
    curPage: 1,
    limit: 2,
    type: 1,
    checkInvoices:true
  })
}

// 重定向到sso
// eslint-disable-next-line no-unused-vars
function redirectToSso({ token = '' }) {
  const action = window.location.href;
  // alert(action, 'laizhe ');
  window.encodeURIComponent(action);

  let redirectUrl = `${process.env.VUE_APP_SSO_DOMAIN}/hcp/user/sso/ssoCheck?tokenType=${0}&action=${window.encodeURIComponent(action)
    }`;
  // alert(redirectUrl);

  // let redirectUrl = `${process.env.VUE_APP_SSO_DOMAIN}/hcp/user/sso/ssoCheck?tokenType=${0}&action=${
  //   window.encodeURIComponent(action)
  // }`;
  // token&& alert(redirect_url += `&token=${ token }`)
  if (token) {
    redirectUrl += `&token=${token}`;
  }
  window.location.replace(redirectUrl);
}

const pureToken = (token = '') => token.replace(/"/g, '');

// 取得token
// 有token => sso 鉴权
// 没有token => sso 提取
// query 有token => 登录成功 => 清除token
// query 为login => 未登录 => 清除token

export default function useSso() {
  const ssoFinish = ref(false);
  // 遇到登录逻辑则忽略
  ssoFinish.value = true;
  const queryToken = pureToken(locationUtils.getParameterByName('token'));
  const localToken = pureToken(localStorageUtils.getItem('token'));
  const queryLoginToken = pureToken(locationUtils.getParameterByName('loginToken'));

  // 查询参数有token -> sso 结束
  // 走完登录流程要去sso中心种一下
  if (queryLoginToken) {
    // ssoFinish.value = true;
    // 去除loginToken防止重定向
    clearLoginTokenString(router);
    console.log(window.location.href, '不重定向先');
    redirectToSso({ token: queryLoginToken });
  } else if (queryToken) {
    // sso 后 -> 获得有效token -> 设置长存token
    if (queryToken !== 'login') {
      localStorageUtils.setItem('token', `"${queryToken}"`);
      getInvoices()
    }
    clearQueryString(router);
    ssoFinish.value = true;
    // 无法获得有效token
    // if (queryToken === 'login') {
    //
    // }
  } else if (localToken) {
    // 本地有token -> 长存token -> 去sso验证 -> 清除本地token -> sso验证开始
    localStorageUtils.removeItem('token');
    redirectToSso({ token: localToken });
  } else {
    redirectToSso({});
  }

  return {
    ssoFinish,
  };
}
