/* eslint-disable */
import router from '../router';
import { mapState } from 'vuex'
import {localStorageUtils} from './storage';


export function pagePush(url) {
  router.push(url);
}

export function connectState(name, keys) {
  return mapState({
    ...formatStates(name, keys)
  })
}

export function formatStates(name, keys) {
  let obj;
  Array.isArray(keys) ? keys.forEach((item) => {
    obj = {
      ...obj,
      [item]: (state) => state[name][item]
    }
  }) : {}
  return obj;
}

export function getLocalToken() {
  return localStorageUtils.getItem('token');
}


/**
 * 进入/退出全屏的方法
 * @type {{fullScreenListener: fullScreenUtils.fullScreenListener, launchFullScreen: fullScreenUtils.launchFullScreen, exitFullScreen: fullScreenUtils.exitFullScreen}}
 */
export const fullScreenUtils = {
  launchFullScreen: function (element) {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();//火狐浏览器的请求全屏
    } else if (element.webkitRequestFullScreen) {
      element.webkitRequestFullScreen();//谷歌、Safari浏览器的请求全屏
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();//IE浏览器的请求全屏
    } else {
      console.error('您的浏览器不兼容全屏模式，推荐您使用360，Chrome，safari，Firefox，QQ浏览器');
    }
  },
  exitFullScreen: function () {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitCancelFullScreen) {
      document.webkitCancelFullScreen();
    } else {
      console.error('您的浏览器不兼容全屏模式，推荐您使用360，Chrome，safari，Firefox，QQ浏览器');
    }
  },
  fullScreenListener: function (cb, element) {
    let ele = element || document;
    let event = fullscreenEvent(ele)
    document.addEventListener(event, function (e) {
      let activeElement = currentFullscreenElement();
      if (activeElement) {
        console.log('进入全屏');
        cb && cb(true);
      } else {
        console.log('退出全屏');
        cb && cb(false);
      }
    });
    ele.onfullscreenerror = function (event) {
      console.error("error :Unable to switch into full-screen mode. ", event)
    }
  },
};

/**
 * 检测是不是IE浏览器
 * @returns {boolean}
 */
export function checkIsIEBrowser(){
  return !!window.ActiveXObject || "ActiveXObject" in window
}
