export default {
  namespaced: true,
  state: {
    barrages: [], // 弹幕
    barragePause: false, // 弹幕开关
    classStatus: null, // 直播状态 0 未开始 1 直播中 -1 已结束
    onLineNumber: 0, // 在线人数
    memberList: [],
    volume: 1, // 声音大小 [0-1]
    sideViewFold: false, // 右侧侧边栏是否折叠
    switchMode: false, // 是否切换模式，//主位为主播放器时:false,//主位为摄像头播放器时：true
    showCamera: true, // 是否打开摄像头画面
    isFullScreen: false, // 是否处于全屏状态
    applyStatus: 0, // 0 未申请 1 申请中 2 连麦中
    ME: {}, // 当前直播间返回的用户信息
    upUserData: {}, // 上讲台用户信息
    upUserList: [], // 当前连麦用户列表
    platformStatus: false,
    lineSetting: false, // 切换线路中
    liveMode: 0,
    switch: false, // 切换摄像头
    messageList: [],

  },
  actions: {

  },
  mutations: {
    UPDATE_STATE_VALUE(state, payload) {
      const { key, data } = payload || {};
      state[key] = data;
    },
    ADD_BARRAGES(state, data) { // 增加弹幕
      if (Array.isArray(data)) {
        // 筛选出当前会话的消息
        state.messageList = [...state.messageList, ...data];
        state.barrages = [...state.barrages, ...data];
      }
    },
    UPDATE_Full_SCREEN(state, status) {
      state.isFullScreen = !!status;
      state.barragePause = !status;
    },
    CLEAR_USER_MESSAGE(state, xid) {
      if (xid) {
        const arr = state.messageList;
        state.messageList = arr.filter((message) => message.xid * 1 !== xid * 1);
      }
    },
  },
};
