<template>
  <div class="global_top_nav_container">
    <div class="w1200_container">
      <div class="nav-left">
        <img
          class="logo"
          src="../assets/img/logo.png"
          alt=""
        />
        <!-- <ul class="global_nav_box">
          <li
            class="header_title_nav"
            v-for="item in globalNavs"
            :key="item.key"
            @click="handlePush(item)"
          >
            <div class="text">{{ item.label }}</div>
            <div class="line" v-show="currentNavKey === item.key"></div>
          </li>
        </ul> -->
      </div>
      <div class="nav_middle">
        <slot></slot>
      </div>
      <div class="nav_right">
        <div
          class="login_nav_container"
          v-if="!!userInfo"
          @mouseover="showUserNav = true"
        >
          <div class="login_user">
            <span>{{ userInfo.userName }}</span>
            <img
              class="avatar"
              v-if="userInfo.avatar"
              :src="userInfo.avatar"
              alt=""
            />
            <img
              class="avatar"
              v-else
              src="../assets/img/default_avatar.png"
              alt=""
            />
          </div>
          <ul
            class="login_navs"
            v-show="showUserNav"
            @mouseleave="showUserNav = false"
          >
            <li
              class="nav_item"
              v-for="item in topNavs"
              :key="item.key"
              @click="handlePush(item)"
            >
              {{ item.label }}
            </li>
          </ul>
        </div>
        <div class="login_btn" v-else @click="handleLogin">登录/注册</div>
      </div>
    </div>
  </div>
</template>

<script>
import { globalNavs, userNavs } from "@/navConfig";
import { connectState } from "../utils/wyUtil";

export default {
  name: "topNav",
  props: {
    showInvoices: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      globalNavs,
      userNavs,
      showUserNav: false,
    };
  },
  computed: {
    ...connectState("login", ["userInfo"]),
    ...connectState("global", ["currentNavKey"]),
    ...connectState("order",['hasInvoices']),
    topNavs() {
      return this.hasInvoices
        ? userNavs
        : userNavs.filter((item) => item.key !== "invoices" && item);
    },
  },
  created() {
    const bindSeller = JSON.parse(localStorage.getItem("bindSeller"));
    if (bindSeller && bindSeller.sid) {
      this.$store.dispatch("goods/bindSeller", bindSeller);
    }
  },
  methods: {
    handleLogin() {
      this.$store.dispatch("login/redirectCommonLogin");
    },
    handlePush(item) {
      if (item.key === "loginOut") {
        this.$store.dispatch("login/userLoginOut");
        return false;
      }
      this.$router.push(item.path);
      return true;
    },
    handleDirectHome() {
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss" scoped>
.global_top_nav_container {
  width: 100vw;
  height: 60px;
  background-color: #3377ff;
  color: #fff;
  position: relative;
  z-index: 9999;
  .w1200_container {
    width: 1200px;
    height: 60px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .nav-left {
      flex: none;
      height: 60px;
      display: flex;
      flex-direction: row;
      .logo {
        flex: none;
        width: 58px;
        height: 30px;
        margin: auto 0;
        cursor: pointer;
      }
      .global_nav_box {
        flex: 1;
        height: 36px;
        margin: auto 30px;
        .header_title_nav {
          width: 80px;
          height: 32px;
          cursor: pointer;
          font-size: 18px;
          font-weight: 500;
          text-align: center;
          margin-top: 4px;
          &:hover {
            font-weight: 400;
          }
          .line {
            width: 26px;
            height: 4px;
            background: #fff;
            border-radius: 5px;
            margin: auto;
          }
        }
      }
    }
    .nav_middle {
      flex: none;
    }
    .nav_right {
      flex: none;
      .login_btn {
        flex: none;
        width: 111px;
        height: 38px;
        line-height: 38px;
        background: #ffffff;
        border-radius: 19px;
        padding: 0 15px;
        color: #3377ff;
        cursor: pointer;
        font-size: 18px;
      }

      .login_nav_container {
        flex: none;
        min-width: 160px;
        position: relative;
        .login_user {
          width: 100%;
          height: 38px;
          line-height: 38px;
          text-align: center;
          color: #fff;
          cursor: pointer;
          .avatar {
            width: 36px;
            height: 36px;
            border: 1px solid #eee;
            border-radius: 50%;
            margin-left: 15px;
          }
        }
        .login_navs {
          position: absolute;
          top: 49px;
          left: 0;
          width: 100%;
          line-height: 32px;
          box-shadow: 0px 2px 16px 0px rgba(202, 205, 214, 0.4);
          border-radius: 0 0 5px 5px;
          background: #fff;
          color: #a0a2ab;
          text-align: center;
          z-index: 9999;
          font-size: 15px;
          padding-bottom: 10px;

          .nav_item {
            cursor: pointer;
            &:hover {
              color: #3377ff;
            }
          }
        }
      }
    }
  }
}
</style>
