<template>
  <div>
    <slot></slot>
  </div>
</template>
<script>
import { connectState } from '../../utils/wyUtil';
import {
  getSearchToken, getLocalToken,
  // setLocalToken,
  // deleteTokenPath,
  getUserInfo,
} from './util';
/**
 * 如果paramsToken存在,设置为本地token，并获取用户信息
 */
/**
 * 如果paramsToken不存在
 * 如果localToken 存在，检测token有效状态，并获取用户信息
 * 如果localToken不存在，监测sso流程
 * 如果已经走过sso流程，token依然不存在，就清空用户信息，待用户主动登录
 */
export default {
  name: 'authentication',
  computed: {
    ...connectState('login', ['ssoFlag']),
  },
  created() {
    this.initUserInfo();
    const queryToken = getSearchToken();
    if (queryToken) {
      // setLocalToken(loginToken);
      this.getUserInfo(queryToken);
      // const rePath = deleteTokenPath();
      // this.$router.replace(rePath);
      return false;
    }
    const localToken = getLocalToken();
    if (localToken) {
      this.getUserInfo(localToken);
    } else if (this.ssoFlag) {
      this.clearUserInfo();
    }
    return 1;
  },
  methods: {
    getUserInfo() {
      this.$store.dispatch('login/fetchUserInfo');
    },
    clearUserInfo() {
      this.$store.dispatch('login/removeUserInfo');
    },
    initUserInfo() {
      // 从localStorage中全局更新userInfo;
      const payload = getUserInfo();
      this.$store.commit('login/UPDATE_USER_INFO', payload);
    },

  },
};
</script>
<style lang="scss" scoped>

</style>
