/* eslint-disable */
import request from '@/utils/request';

//获取用户优惠券列表信息
export function queryCouponList(payload) {
  return request('/hcp/user/myCoupon/page', {
    method: 'get',
    params: payload,
  })
}
//获取优惠券可用商品列表
export function couponRelateGoods(payload){
  return request('/hcp/trade/coupon/citem', {
    method: 'get',
    params: payload,
  })
}
