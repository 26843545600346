/* eslint-disable */
import { createStore } from 'vuex';
import writing from './moudles/writing';
import login from './moudles/login';
import course from './moudles/course';
import live from "./moudles/live";
import oral from "./moudles/oral";
import pay from './moudles/pay';
import home from './moudles/home';
import order from './moudles/order';
import goods from './moudles/goods';
import coupon from "./moudles/coupon"
import global from "./moudles/global"

export default createStore({
  state: {
    currentNav:"home"
  },
  mutations: {},
  actions: {

  },
  modules: {
    writing,
    login,
    course,
    live,
    oral,
    pay,
    order,
    home,
    coupon,
    goods,
    global
  },
  getters: {
    userInfo: state => state.login.userInfo || {},
    sectionDetail: state => state.course.sectionDetail,
  }
});
