<template>
  <authentication>
    <top-nav/>
  </authentication>
  <router-view/>
</template>

<script>
import Authentication from '@/components/Authentication/index.vue';
import TopNav from '@/components/TopNav.vue';

export default {
  components: { Authentication, TopNav },
};
</script>

<style>

</style>
