<template>
  <div class="entry_page_container">
    <authentication>
      <top-nav :show-invoices="hasInvoices" />
      <div class="layout">
        <div class="layout_page_container">
          <slider-bar class="leftBox" :show-invoices="hasInvoices" />
          <div class="rightBox">
            <router-view> </router-view>
          </div>
        </div>
      </div>
    </authentication>
  </div>
</template>

<script>
import TopNav from "../TopNav.vue";
import SliderBar from "./SliderBar.vue";
import Authentication from "../Authentication/index.vue";
import { connectState } from "@/utils/wyUtil";

export default {
  components: {
    Authentication,
    SliderBar,
    TopNav,
  },
  data() {
    return {};
  },
  computed: {
    ...connectState("order",["hasInvoices"])
  },
};
</script>

<style lang="scss" scoped>
.entry_page_container {
  width: 100vw;
  .layout {
    width: 1200px;
    margin: auto;
    border-radius: 6px;
    .layout_page_container {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      padding-top: 45px;

      .leftBox {
        flex: none;
        width: 226px;
        border-radius: 6px;
      }

      .rightBox {
        width: 948px;
        border-radius: 6px;
        overflow-x: hidden;
      }
    }
  }
}
</style>
