// 固定排序的数组
const sortArray = [
  {
    itemCode: 'YZTG20220526103438975217',
    itemName: '【通关全科班】雅思哥一线导师全科班',
  },
  {
    itemCode: 'XXKZ20210813100246784852',
    itemName: '【多科目优惠】单科技巧预测班，支持任选班级',
  },
  {
    itemCode: 'XTYB20230504164623102485',
    itemName: 'A/G类适用丨2023年屠鸭冲刺全科班',
  },
  {
    itemCode: 'YJQK20220429192841643200',
    itemName: '【冲刺全科班】杨涛老师全科冲刺班',
  },
  {
    itemCode: 'TARA20210812164607175656',
    itemName: '雅思口语：Tara老师技巧直播班',
  },
  {
    itemCode: 'YULE20210812171804438228',
    itemName: '雅思口语：于磊老师单科技巧预测班',
  },
  {
    itemCode: 'HAOY20210803160317149822',
    itemName: '雅思口语：郝妍老师单科技巧预测班',
  },
  {
    itemCode: 'DUSI20210810114609035348',
    itemName: '雅思写作：杜斯迅老师单科技巧预测班',
  },
  {
    itemCode: 'AMAN20210816190954608465',
    itemName: '雅思听力：Amanda老师单科技巧预测班',
  },
  {
    itemCode: 'LULU20220311115759090141',
    itemName: '雅思听力：周璐老师单科技巧预测班',
  },
  {
    itemCode: 'NANA20230606110415786617',
    itemName: '雅思听力：郭娜老师技巧预测暑假班',
  },
  {
    itemCode: 'XUWE20210805161717792503',
    itemName: '雅思写作：徐卫老师单科技巧预测班',
  },
  {
    itemCode: 'CWEN20210810155515288958',
    itemName: '雅思阅读：陈雯老师单科技巧预测班',
  },
  {
    itemCode: 'AMYD20230517181132258339',
    itemName: '雅思阅读：Amanda老师单科技巧预测班',
  },
  {
    itemCode: 'CWYD20230523133834404215',
    itemName: '雅思阅读：陈雯老师1V1阅读冲刺套课',
  },
  {
    itemCode: 'GNYV20230506165300130876',
    itemName: '雅思听力：郭娜老师1V1听力冲刺套课',
  },
  {
    itemCode: 'YTDC20220121170126560160',
    itemName: '雅思听力：杨涛老师提升冲刺班',
  },
  {
    itemCode: 'YTDC20220121171119497608',
    itemName: '雅思写作：杨涛老师预测冲刺班',
  },
  {
    itemCode: 'YTDC20220519153003953576',
    itemName: '雅思阅读：杨涛老师提升冲刺班',
  },
  {
    itemCode: 'YTDC20220519153618605506',
    itemName: '雅思口语：杨涛老师口语预测冲刺班',
  },
  {
    itemCode: 'AMLR20230529151414829822',
    itemName: 'Amanda听力阅读双科直播班丨单科双科学习卡',
  },
  {
    itemCode: 'TFQX20230628170007965427',
    itemName: '暑期备考通关丨四天四科雅思集训',
  },
];

function sortClassList(list) {
  let result = [];
  // 接口返回，如果包含固定排列数组里的数据，则按这个排序
  sortArray.forEach((item) => {
    list.forEach((item2) => {
      if (item.itemCode === item2.itemCode) {
        result.push(item2);
      }
    });
  });
  function getArrNoRepeat(arr1, arr2) {
    return arr1.filter((item) => !arr2.includes(item));
  }
  result = getArrNoRepeat(list, result).concat(result);
  return result;
}

export default sortClassList;
