/* eslint-disable */
import http from '@/utils/request';
import { formatCatalog } from '@/utils/format';

export default {
  namespaced: true,
  state: {
    payResText: '',
    qrCodeUrl: '',
    wxNum: '',
    menuUrl: '',
    orderInfo: {},
  },
  mutations: {
    UPDATE_VALUE_STATES(state, payload) {
      const { key, data } = payload;
      state[key] = data;
    },
  },
  actions: {
    // 获取订单支付状态
    async getOrderStatus({ dispatch, commit }, payload) {
      const res = await http(`/hcp/trade/tradeOrder/h5Status`, { params: { ...payload } });
      commit('UPDATE_VALUE_STATES', { key: 'orderInfo', data: res })
      let payResText, qrCodeUrl, wxNum, menuUrl;
      if (res.orderStatus === 2) {
        //支付成功
        if (res.type === 1) {
          if (res.wechatCodeUrl) {
            let obj = JSON.parse(res.wechatCodeUrl);
            qrCodeUrl = obj.url || "";
            wxNum = obj.account || "";
          } else {
            qrCodeUrl = "";
            wxNum = "";
          }
        } else {
          function isJSON(str) {
            if (typeof str == 'string') {
              try {
                var obj = JSON.parse(str);
                if (typeof obj == 'object' && obj) {
                  return true;
                } else {
                  return false;
                }

              } catch (e) {
                console.log('error：' + str + '!!!' + e);
                return false;
              }
            }
            console.log('It is not a string!')
          }
          if (isJSON(res.wechat)) {
            wxNum = res.wechat.account
          } else {
            wxNum = res.wechat;
          }
          qrCodeUrl = res.wechatCodeUrl;
        }
        payResText = '支付成功';
        menuUrl = formatCatalog(res.itemCatalog);
        sessionStorage.removeItem('codeUrl');
      } else {
        payResText = '支付失败';
      }
      commit('UPDATE_VALUE_STATES', { key: 'payResText', data: payResText })
      commit('UPDATE_VALUE_STATES', { key: 'qrCodeUrl', data: qrCodeUrl })
      commit('UPDATE_VALUE_STATES', { key: 'wxNum', data: wxNum })
      commit('UPDATE_VALUE_STATES', { key: 'menuUrl', data: menuUrl })
    },
  }
};
