import moment from "moment-timezone";
/* eslint-disable */
// 回车键转换成br
export function formatBr(val) {
  const reg = /\n/g;
  if (reg.test(val)) {
    return val.replace(/\n/g, "<br/>")
  } else {
    return val
  }
}

// br转换成回车键
export function formatEnter(val) {
  if(!val) return
  if (val.indexOf('<br/>' !== -1)) {
    return val.replace(/<br\/>/g, '\n')
  } else {
    return val
  }
}

// 商品分类
export function formatCatalog(val) {
  let tab;
  switch (val) {
    case 2:
    case 3:
      tab = 'userCourses';
      break;
    case 4:
    case 14:
    case 16:
    case 17:
      tab = 'writing';
      break;
    case 5:
    case 15:
      tab = 'oral';
      break;
    case 21:
      tab="checkList";
      break;
  }
  return tab;
}

// 格式化商品详情
export function formatGoods(item) {
  item.sellingPoint = item.itemList[0].sellingPoint
  item.showOriginalPrice = item.itemList[0].showOriginalPrice
  item.skuDesc = item.itemList[0].skuDesc
}

// 格式化课程列表
export function formatCourseItemData(data) {
  return Array.isArray(data) ? data.map(item => {
    return item || {};
  }) : data
}

// 章类型/课程类型(0：口语 1：听力 2：阅读 3：写作 4：语法)
export function formatChargeChapters(data, typesObj) {
  let obj = typesObj || {
    '0': '口语',
    '1': '听力',
    '2': '阅读',
    '3': '写作',
    '4': '语法'
  }
  return Object.keys(data).map(key => {
    return {
      key: key,
      label: obj[key],
      list: formatCourseItemData(data[key * 1])
    }
  }) || []
}

// js浮点数bug 减
export function sub(a, b) {
  var c, d, e;
  try {
    c = a.toString().split(".")[1].length;
  } catch (f) {
    c = 0;
  }
  try {
    d = b.toString().split(".")[1].length;
  } catch (f) {
    d = 0;
  }
  return e = Math.pow(10, Math.max(c, d)), (mul(a, e) - mul(b, e)) / e;
}

// js浮点数bug 乘
export function mul(a, b) {
  var c = 0,
    d = a.toString(),
    e = b.toString();
  try {
    c += d.split(".")[1].length;
  } catch (f) { }
  try {
    c += e.split(".")[1].length;
  } catch (f) { }
  return Number(d.replace(".", "")) * Number(e.replace(".", "")) / Math.pow(10, c);
}



/**
 * 内容发布时间显示规则:
 发布时间，即用户创建内容的时间
 距离用户发布时间在1分钟内：刚刚
 距离用户发布时间在1小时内：1分钟前，2分钟前，3分钟前，…，59分钟前
 距离用户发布时间超过1小时，在24小时内：1小时前，2小时前，3小时前，…，23小时前
 距离用户发布时间超过24小时，在一周内：1天前，2天前，3天前，…，6天前
 距离用户发布时间超过一周，在本年度内，显示日期：04-30
 距离用户发布时间超过一周，不在本年度内，显示年份日期：2019-12-31
 */

export const publishTimeRule = time => {
  if (!time) return "";
  let now = new Date();
  let nowTime = now.getTime();
  let date = new Date(time);
  let dateTime = date.getTime() || 0;
  let range = nowTime - dateTime;
  let min = 60 * 1000;
  let hh = 60 * min;
  let day = 24 * hh;
  let week = 7 * day;
  if (range < min) {
    return "刚刚";
  } else if (min <= range && range < hh) {
    return parseInt(range / min) + "分钟前";
  } else if (hh <= range && range < day) {
    return parseInt(range / hh) + "小时前";
  } else if (day <= range && range < week) {
    return parseInt(range / day) + "天前";
  } else if (date.getFullYear() === now.getFullYear()) {
    let month = date.getMonth() + 1;
    let d = date.getDate();
    return moment(time).format('MM-DD HH:mm');
  } else {
    let y = date.getFullYear();
    let month = date.getMonth() + 1;
    let d = date.getDate();
    return moment(time).format('YYYY-MM-DD');
  }
};
