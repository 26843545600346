// 移除某个key 的查询参数
function removeURLParameter(url, parameter) {
  // prefer to use l.search if you have a location/link object
  const urlparts = url.split('?');
  if (urlparts.length >= 2) {
    const prefix = `${encodeURIComponent(parameter)}=`;
    const pars = urlparts[1].split(/[&;]/g);
    // reverse iteration as may be destructive
    // eslint-disable-next-line no-plusplus
    for (let i = pars.length; i-- > 0;) {
      // idiom for string.startsWith
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1);
      }
    }

    return urlparts[0] + (pars.length > 0 ? `?${pars.join('&')}` : '');
  }
  return url;
}

// 去掉查询参数 'token' 还有 'loginToken'
// 采用router进行删除
// eslint-disable-next-line import/prefer-default-export
export const clearQueryString = (router) => {
  // eslint-disable-next-line no-underscore-dangle
  const keys = ['token'];
  let clearSearch = window.location.search;
  keys.forEach((el) => {
    clearSearch = removeURLParameter(clearSearch, el);
  });
  if (router) {
    router.replace(window.location.pathname + clearSearch);
  }
  // window.history.replaceState(
  //   window.history.state,
  //   document.title,
  //   resultUrl,
  // );
  // console.log(window.history.state,
  //   document.title,
  //   resultUrl, 'suanqiu');
};

export const clearLoginTokenString = (router) => {
  // eslint-disable-next-line no-underscore-dangle
  const keys = ['loginToken'];
  let clearSearch = window.location.search;
  keys.forEach((el) => {
    clearSearch = removeURLParameter(clearSearch, el);
  });

  if (router) {
    router.replace(window.location.pathname + clearSearch);
  }

  window.history.replaceState(
    window.history.state,
    document.title,
    window.location.pathname + clearSearch,
  );
  // console.log(window.history.state,
  //   document.title,
  //   resultUrl, 'suanqiu');
};
