/* eslint-disable */
import axios from 'axios';
import {getLocalToken} from './wyUtil';
import { ElMessage } from 'element-plus'
import store from "../store"

/**
 * 无效token，需要重新登录
 (10032, "登录失效"),
 (10033, "token失效"),
 (10034, "token验证失败"),
 (10039, "token为空")
 (10042, "token解析失败")
 (10020, "token不能为空")
 (20006, "用户 token 类型不存在"),
 (20010, "token 类型为空"),
 (20016, "用户ID和token必须满足其一不能为空")
 */

const tokenInvalidList = [10020, 10032, 10033, 10034, 10039, 10042, 20006, 20010, 20016];

axios.defaults.timeout = 60000;/** 1min 测试 */
axios.defaults.headers['Content-Type'] = 'application/json';

export default function request(url, options) {
  let {
    method = 'GET', params, headers, data = {}, callback, ...rest
  } = options || {};
  headers = headers || {};
  return axios({
    url,
    method,
    params: { ...params },
    headers: {...headers },
    data,
    ...rest,
  })
    .then((res) => (res))
    .catch((err) => {
      err.errOptions = options;
      throw err;
    });
}

// 通用的 token 与 source 参数
axios.interceptors.request.use((config) => {
  const token = getLocalToken();
  if (token) {
    config.headers.source = process.env.VUE_APP_LOGIN_TYPE;
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

axios.interceptors.response.use((res) => {
  if (res.data.status === 0) {
    return res.data.content;
  } else if (res.data.status === 20058) {
    return res.data.status
  } else if (tokenInvalidList.includes(res.data.status)) { /** 登陆失效 */
    ElMessage({type:'error',message:"登录信息失效..."})
    setTimeout(()=>{
      store.dispatch("login/redirectCommonLogin")
    },1000)
    return Promise.reject(new Error(res.data.status));
  } else if (res.data.status === 600396) {
    return res
  }
  const err = new Error(res.data.message);
  err.response = res;
  ElMessage({type: 'error', message: res.data.message});
  return Promise.reject(err);
});
