/* eslint-disable */
import http from '@/utils/request';
import { formatChargeChapters } from "@/utils/format";

const createTabs = catalog => {
  let base = [
      { label: "详情", name: "detail" },
      { label: "评价", name: "comment" }
  ];
  if ([2, 3].includes(catalog)) {
      base.splice(1, 0, { label: "课表", name: "courseTable" });
  }
  if ([21].includes(catalog)) {
    base.push({ label: "师资阵容", name: "teachers" });
  }
  return base.map((item, index) => ({ ...item, value: index }));
};

export default{
  namespaced: true,
  state: {
    goodsInfo: {},
    goodsType: null,
    tabs: [{ label: '详情', value: 0 ,name:"detail"}],
    itemList: [],
    skuInfo: {},
    courseInfo: {},
    chaptersType: {},
    courseList: [],
    commentList: [],
    preWxNum: '',
    aftWxNum: '',
    wxNum: '',
    wxUrl: '',
    preUrl: '',
    aftUrl: '',
    couponList: [],
    agreement: '',
    goodsData: {},
    orderInfo: [],
    itemCatalog: null,
    isHasCourse: false
  },
  mutations: {
    UPDATE_VALUE_STATES(state, payload) {
      const { key, data } = payload;
      state[key] = data;
    },
    CHANGE_SKU(state, i) {
      state.skuInfo = state.itemList[i]
    }
  },
  actions: {
    // 纯获取商品详情
    async getGoodsDesc({ commit }, payload) {
      const { params, skuIndex, callback } = payload
      const res = await http(`/hcp/trade/item/getByCode`, {params});
      let { itemName, itemList, imageUrl, orderInfo, payType, itemCatalog } = res[0]
      const { price } = itemList[skuIndex]
      const goodsData = {
        title: itemName,
        price,
        url: itemList[skuIndex].picture || imageUrl,
        itemId: itemList[skuIndex].itemId,
        originPrice: itemList[skuIndex].originalPrice,
        showOriginalPrice: itemList[skuIndex].showOriginalPrice
      }
      orderInfo = orderInfo ? orderInfo.split('_') : [];
      commit('UPDATE_VALUE_STATES', {key: 'goodsData', data: goodsData})
      commit('UPDATE_VALUE_STATES', {key: 'goodsType', data: itemCatalog})
      commit('UPDATE_VALUE_STATES', {key: 'orderInfo', data: orderInfo})
      commit('UPDATE_VALUE_STATES', {key: 'itemCatalog', data: itemCatalog})
      callback && callback({ price, payType })
    },
    // 获取商品详情
    async getGoodsInfo({ dispatch, commit, state }, params) {
      commit('UPDATE_VALUE_STATES', {key: 'courseInfo', data: {}})
      const res = await http(`/hcp/trade/item/getByCode`, {params});
      const {itemCode, itemList, itemName, itemCatalog, preSale, aftSale} = res[0]
      const preVal = preSale
      const aftVal = aftSale
      if (preVal) {
        let reg = /^http/g;
        let _obj =
          typeof preVal === "string" && reg.test(preVal)
            ? preVal
            : JSON.parse(preVal);
        const obj = _obj && typeof _obj === "object" ? _obj : {};
        commit('UPDATE_VALUE_STATES', {key: 'preWxNum', data: obj.account})
        commit('UPDATE_VALUE_STATES', {key: 'preUrl', data: obj.url})
      } else {
        commit('UPDATE_VALUE_STATES', {key: 'preUrl', data: null })
      }
      if (aftVal) {
        let reg = /^http/g;
        let _obj =
          typeof aftVal === "string" && reg.test(aftVal)
            ? aftVal
            : JSON.parse(aftVal);
        const obj = _obj && typeof _obj === "object" ? _obj : {};
        commit('UPDATE_VALUE_STATES', {key: 'aftWxNum', data: obj.account})
        commit('UPDATE_VALUE_STATES', {key: 'aftUrl', data: obj.url})
      } else {
        commit('UPDATE_VALUE_STATES', {key: 'aftUrl', data: null })
      }
      commit('UPDATE_VALUE_STATES', {key: 'goodsInfo', data: res[0]})
      commit('UPDATE_VALUE_STATES', {key: 'goodsType', data: itemCatalog})
      commit('UPDATE_VALUE_STATES', {key: 'itemList', data: itemList})
      commit('UPDATE_VALUE_STATES', {key: 'skuInfo', data: itemList[0]})
      commit('UPDATE_VALUE_STATES', {key: 'tabs', data: createTabs(itemCatalog)})
      if(![2,3].includes(itemCatalog)) dispatch('getAssistant', {itemCode})
      dispatch('getGoodsLog', {itemCode, itemName, itemCatalog})
    },
    // 判断用户是否购买过相同课程
    async isBuyCourse({commit}, params) {
      const res = await http(`/hcp/trade/tradeOrder/hasClass`, {params})
      commit('UPDATE_VALUE_STATES', {key: 'isHasCourse', data: res})
    },
    // 获取课程详情
    async getCourseInfo({ dispatch, commit }, params) {
      const res = await http(`/hcp/yida/chargeCourse/detail`, {params});
      commit('UPDATE_VALUE_STATES', {key: 'courseInfo', data: res || {}})
      const adminValue = res.classAdviserWechat;
      if (adminValue) {
        let reg = /^http/g;
        let _obj =
          typeof adminValue === "string" && reg.test(adminValue)
            ? adminValue
            : JSON.parse(adminValue);
        const obj = _obj && typeof _obj === "object" ? _obj : {};
        // commit('UPDATE_VALUE_STATES', {key: 'wxNum', data: obj.account})
        // commit('UPDATE_VALUE_STATES', {key: 'wxUrl', data: obj.url})
      }
    },
    // 获取章节类型
    async getChaptersType({ commit }, callback) {
      const res = await http('/hcp/base/base/dict/ChapterType')
      let obj = {};
      Array.isArray(res) && res.map((item) => {
          let keyName = item.key
          obj = { ...obj, [keyName]: item.value }
      })
      callback(obj)
    },
    // 获取章节排序
    async getChaptersSort({ commit }, payload) {
      const {params, callback} = payload
      const res = await http('/hcp/yida/chargeCourse/getCourseTypeSort', {params})
      callback(res)
    },
    // 获取课程列表
    async getCourseList({ dispatch, commit }, params) {
      dispatch('getChaptersSort', {params, callback: (sortObj) => {
        dispatch('getChaptersType', async (obj) => {
          const res = await http(`/hcp/yida/chargeCourse/curriculum`, {params});
          let data = formatChargeChapters(res, obj) || []
          const list = data.map((item) => {
            let sort=(isNaN(sortObj[item.key]) ||sortObj[item.key]*1>999)?999:sortObj[item.key]*1
            item.sort = sort
            return item
          }) || [];
          data = list.sort((a,b) => (a.sort * 1 - b.sort * 1))
          commit('UPDATE_VALUE_STATES', {key: 'courseList', data})
        })
      }})
    },
    // 获取评价列表
    async getCommentList({ dispatch, commit }, data) {
      const res = await http(`/hcp/user/comment/getSimpleCommentPage`, {method: 'POST', data });
      commit('UPDATE_VALUE_STATES', {key: 'commentList', data: res.list || []})
    },
    // 获取评价列表
    async getItemCommentsPage({ dispatch, commit }, data) {
      const res = await http(`/hcp/user/comment/getItemCommentsPage`, {method: 'POST', data });
      commit('UPDATE_VALUE_STATES', {key: 'commentList', data: res.list || []})
    },
    // 获取商品小助手
    async getAssistant({ dispatch, commit }, params) {
      const res = await http(`/hcp/trade/item/getAssistant`, {params});
      commit('UPDATE_VALUE_STATES', {key: 'wxNum', data: res && res.wechat || ''})
      commit('UPDATE_VALUE_STATES', {key: 'wxUrl', data: res && res.wechatCodeUrl || ''})
    },
    // 获取优惠券列表
    async getCouponList({ dispatch, commit }, params) {
      const { itemCode, callback } = params;
      const res = await http(`/hcp/trade/coupon/ucl`, {params: {itemCode}});
      commit('UPDATE_VALUE_STATES', {key: 'couponList', data: res || []})
      callback(res)
    },
    // 获取服务协议
    async getAgreement({ dispatch, commit }, type) {
      const res = await http(`/hcp/base/base/documentYidaHtml/${type}`);
      commit('UPDATE_VALUE_STATES', {key: 'agreement', data: res.content || '暂无内容'})
    },
    // 商品埋点
    async getGoodsLog({ dispatch, commit }, data) {
      await http(`/hcp/trade/item/saveItemBrowingLog`, {method: 'POST', data });
    },
    // 绑定销售员
    async bindSeller({dispatch, commit}, data) {
      await http('/hcp/trade/item/binds', { method: 'POST', data })
      localStorage.removeItem('bindSeller')
    }
  }
};
