/* eslint-disable */
import { localStorageUtils } from '@/utils/storage';
import { queryUserInfo,userLoginOutFromWebsite,getUserExamInfo,editUserInfo,
  editUserExamInfo,editPassword,fetchExamDates } from '@/services/user';
import router from "../../router"

export default {
  namespaced: true,
  state: {
    ssoFlag: false, // 是否已走sso流程
    userInfo: null, // 用户信息
    examInfo:{},
    examDates:[]
  },
  actions: {
    redirectCommonLogin(_, payload = {}) {
      // 13-易答PC客户端
      const action = payload.action || window.location.href;
      const source = payload.source || process.env.VUE_APP_LOGIN_TYPE;
      const params = `/?source=${source}&loginType=${source}&action=${encodeURIComponent(action)}`;
      window.location.href = process.env.VUE_APP_LOGIN_URL + params;
    },
    removeUserInfo() {
      localStorageUtils.removeItem('userInfo');
      localStorageUtils.removeItem('token');
    },
    async fetchUserInfo({ commit }) {
      const content = await queryUserInfo();
      commit('UPDATE_USER_INFO', content);
    },
    async userLoginOut({dispatch, commit },payload){
      await userLoginOutFromWebsite({
        source:process.env.VUE_APP_LOGIN_TYPE,
        token:localStorageUtils.getItem('token')
      })
      dispatch("removeUserInfo")
      dispatch("clearUserState")
      // dispatch("userToHome")
      dispatch("redirectCommonLogin")
    },
     userToHome({dispatch, commit },payload){
      router.push("/home")
    },
    async clearUserState({ commit }){
      commit("UPDATE_STATE_VALUE",{key:'token',data:""})
      commit("UPDATE_USER_INFO",null)
    },
    async fetchExamInfo({dispatch, commit },payload){
      const content =await getUserExamInfo()
      commit('UPDATE_STATE_VALUE',{key:'examInfo',data:content})
    },
    async updateUserInfo({dispatch, commit },payload){
      const {callback,...rest}=payload;
      await editUserInfo(rest)
      typeof callback==="function" &&callback()
    },
    async updateUserExamInfo({dispatch, commit },payload){
      const {callback,...rest}=payload;
      await editUserExamInfo(rest)
      typeof callback==="function" &&callback()
    },
    async updatePassword({dispatch, commit },payload){
      const {callback,...rest}=payload;
      await editPassword(rest)
      typeof callback==="function" &&callback()
    },
    async queryExamDates({dispatch, commit },payload){
      const content=await fetchExamDates(payload)
      let list=Array.isArray(content)?content.map((item)=>{
        return {
          date: item.examDate,
          examDateId: item.timeId
        }
        }):[]
      commit('UPDATE_STATE_VALUE',{key:"examDates",data:list})

    }
  },
  mutations: {
    UPDATE_STATE_VALUE(state, payload) {
      const { key, data } = payload || {};
      state[key] = data;
    },
    UPDATE_USER_INFO(state, payload) {
      state.userInfo = payload || null;
    },

  },
};
