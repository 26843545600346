/* eslint-disable */
import { createApp } from 'vue';
import ElementPlus from 'element-plus';
import App from './App.vue';
import router from './router';
import store from './store';
import 'element-plus/lib/theme-chalk/index.css';
import locale from 'element-plus/lib/locale/lang/zh-cn'
import 'dayjs/locale/zh-cn'
import './assets/css/common.scss';
import './assets/css/element-variables.scss';
import './global.scss';
import infiniteScroll from 'vue3-infinite-scroll-good'

const app = createApp(App);
app.use(infiniteScroll)
app.use(store);
app.use(router);
app.use(ElementPlus, { locale });
app.mount('#app');
export default app;
