/* eslint-disable */
import http from "@/utils/request";
import { formatBr, formatEnter } from "@/utils/format";

export default {
  namespaced: true,
  state: {
    submitNum: 0,
    writingGoalScore: 0,
    isWritingExperience: false,
    userWritingName: "",
    writeList: [],
    writeTotal: 0,
    editInfo: {},
    writeInfo: {},
    rates: [],
    restTime: "",
    qaList: []
  },
  mutations: {
    UPDATE_VALUE_STATES(state, payload) {
      const { key, data } = payload;
      state[key] = data;
    }
  },
  actions: {
    // 写作批改次数
    async getSubmitNum({ dispatch, commit }) {
      const res = await http("/hcp/yida/writingCorrecting/getUserableCorrectingInfo");
      const { targetWriteGrade, ifFirstExperience, useableWritingCorrectingCount, nickName } =
        res || {};
      commit("UPDATE_VALUE_STATES", { key: "submitNum", data: useableWritingCorrectingCount });
      commit("UPDATE_VALUE_STATES", { key: "writingGoalScore", data: targetWriteGrade });
      commit("UPDATE_VALUE_STATES", { key: "isWritingExperience", data: ifFirstExperience });
      commit("UPDATE_VALUE_STATES", { key: "userWritingName", data: nickName });
    },
    // 写作批改列表
    async getWriteList({ dispatch, commit }, payload) {
      const res = await http("/hcp/yida/writingCorrecting/page", { method: "POST", data: payload });
      commit("UPDATE_VALUE_STATES", { key: "writeList", data: res.list });
      commit("UPDATE_VALUE_STATES", { key: "writeTotal", data: res.total });
    },
    // 作文详情
    async getWriteInfo({ dispatch, commit }, payload) {
      const res = await http(`/hcp/yida/writingCorrecting/writingDetail/${payload}`);
      const info = JSON.parse(JSON.stringify(res));
      info.subjectContent = formatEnter(info.subjectContent);
      info.writingContent = formatEnter(info.writingContent);
      commit("UPDATE_VALUE_STATES", { key: "editInfo", data: info || {} });
      const obj = JSON.parse(JSON.stringify(res));
      obj.subjectContent = formatBr(obj.subjectContent);
      obj.writingContent = formatBr(obj.writingContent);
      obj.subjectUrl = (obj.subjectUrl && obj.subjectUrl.split(",")) || [];
      commit("UPDATE_VALUE_STATES", { key: "writeInfo", data: obj });
      let score = res.operaterScore || res.teacherScore;
      let rates = score ? score.split(",") : [];
      commit("UPDATE_VALUE_STATES", { key: "rates", data: rates });
      commit("UPDATE_VALUE_STATES", { key: "restTime", data: res.answerRemainTime });
      if (res.status === 6) dispatch("getQaList", payload);
    },
    // 答疑记录
    async getQaList({ dispatch, commit }, payload) {
      const res = await http(`/hcp/yida/writingCorrecting/getWritingAnswerRecordList/${payload}`);
      commit("UPDATE_VALUE_STATES", { key: "qaList", data: res });
    },
    // 下载作文
    async downloadWrite({ dispatch, commit }, id) {
      const res = await http(`/hcp/yida/writingCorrecting/exportWord/${id}`);
      if (res) window.location.href = res;
    },
    async saveInterimWriting({ dispatch, commit }, payload) {
      const { callback, ...rest } = payload;
      await http(`/hcp/yida/writingCorrecting/saveWritingDraft`, {
        method: "post",
        data: rest
      });
      typeof callback === "function" && callback();
    },
    async getInterimWriting({ dispatch, commit }, payload) {
      const content = await http(`/hcp/yida/writingCorrecting/getWritingDraft`);
      commit("UPDATE_VALUE_STATES", { key: "editInfo", data: content || {} });
    }
  }
};
