/* eslint-disable */
export const globalNavs = [
  { label: '首页', key: 'home', path: '/home' },
];

export const userNavs=[
  { label: '我的课程', key: 'course', path: '/userCourses' },
  { label: '写作批改', key: 'writing', path: '/writing' },
  { label: '口语陪练', key: 'oral', path: '/oral' },
  { label: '我的订单', key: 'order', path: '/userOrders' },
  { label: '我的发票', key: 'invoices', path: '/userInvoices' },
  { label: '优惠券', key: 'coupon', path: '/userCoupons' },
  { label: '个人信息', key: 'user', path: '/userInfo' },
  { label: '退出', key: 'loginOut', path: '/' },
]
