// eslint-disable-next-line import/prefer-default-export
export const locationUtils = {
  getParameterByName(name, url) {
    // eslint-disable-next-line no-param-reassign
    if (!url) url = window.location.href;
    // eslint-disable-next-line no-param-reassign
    name = name.replace(/[[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);
    if (!results) return undefined;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  },
  getInsertQueryParams(locationSearch, { key, value }) {
    const search = new URLSearchParams(locationSearch);
    search.append(key, value);
    return search.toString();
  },

  clearQueryByKey(url, key) {
    // prefer to use l.search if you have a location/link object
    const urlparts = url.split('?');
    if (urlparts.length >= 2) {
      const prefix = `${encodeURIComponent(key)}=`;
      const pars = urlparts[1].split(/[&;]/g);
      // reverse iteration as may be destructive
      // eslint-disable-next-line no-plusplus
      for (let i = pars.length; i-- > 0;) {
        // idiom for string.startsWith
        if (pars[i].lastIndexOf(prefix, 0) !== -1) {
          pars.splice(i, 1);
        }
      }

      return urlparts[0] + (pars.length > 0 ? `?${pars.join('&')}` : '');
    }
    return url;
  },
};
