// eslint-disable-next-line import/prefer-default-export
export const localStorageUtils = {
  // 返回键值为key 的本地缓存数据, 如果没有的话, 返回 undefined
  getItem: (key) => {
    let result = localStorage.getItem(key);
    if (result === null) {
      result = undefined;
    }
    return result;
  },
  setItem: (key, value) => {
    localStorage.setItem(key, value);
  },
  removeItem: (key) => {
    localStorage.removeItem(key);
  },
  getLocalObject: (key) => {
    const result = localStorage.getItem(key);
    if (typeof result === 'string' && !!result) {
      return JSON.parse(result);
    }
    return null;
  },
  setLocalObject: (key, object) => {
    if (typeof object === 'object' && object !== null) {
      localStorageUtils.setItem(key, JSON.stringify(object));
    }
  },
};
