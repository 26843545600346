/* eslint-disable */
import request from "@/utils/request";
const querystring = require("querystring");

//获取口语陪练记录列表
export function queryUsersOralRecords(payload) {
  return request("/hcp/yida/course/getOralRecordV2", {
    method: "get",
    params: payload
  });
}

export function queryRestOralCount(payload) {
  return request("/hcp/yida/course/workOrderNum", {
    method: "get",
    params: payload
  });
}

//获取可约课列表
export function queryBookCourseList(payload) {
  //getCourse
  return request("/hcp/yida/course/getCourse", {
    method: "get",
    params: payload
  });
}

//约课
export function userBookCourse(payload) {
  return request("/hcp/yida/course/booked", {
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    data: querystring.stringify({ ...payload })
  });
}

//取消约课
export function userCancelBookCourse(payload) {
  return request("/hcp/yida/course/cancel", {
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    data: querystring.stringify({ ...payload })
  });
}

//评价
export function evaluateCourse(payload) {
  return request("/hcp/user/comment/addSimpleComment", {
    method: "post",
    data: payload
  });
}

export function checkBindTeacher(payload) {
  return request("/hcp/yida/course/getBindTeacher", {
    method: "get",
    params: payload
  });
}

//获取口语陪练小助手
export function getAssistantsWeAccount(payload) {
  return request("/hcp/base/base/image/" + payload, {
    method: "get"
  });
}

//获取上课须知配置值
export function getNotForClass() {
  return request("/hcp/base/base/dict/NotesForClass", {
    method: "get"
  });
}

// 获取口语约课小助手的二维码
export function getOralAssistant(payload) {
  return request("/hcp/yida/course/getOralSalespersonQRCode", {
    method: "get"
  });
}
// 获取口语计划详情
export function getOralDetail(payload) {
  const id = payload.id;
  const planIdMode = payload.planIdMode;
  if (planIdMode) {
    return request(`/hcp/yida/studyPlan/getOralQuestionListByPlanId/${id}`, {
      method: "get"
    });
  } else {
    return request(`/hcp/yida/studyPlan/getOralQuestionByOralTopicId/${id}`, {
      method: "get"
    });
  }
}
