/* eslint-disable */
import { queryUserOrderList, cancelOrder, queryOrderDetail, queryPaySign, queryInvoiceList } from '@/services/order';
import router from "@/router"

export default {
  namespaced: true,
  state: {
    ordersData: {},
    orderDetail: {},
    invoiceData: {},
    hasInvoices:false,
  },
  actions: {
    async getOrderList({ commit }, payload) {
      const content = await queryUserOrderList(payload)
      commit('UPDATE_STATE_VALUE', { key: 'ordersData', data: content })
    },
    async userCancelOrder(_, payload) {
      const { callback, ...rest } = payload;
      await cancelOrder(rest)
      typeof callback === 'function' && callback()
    },
    async getOrderDetail({ commit }, payload) {
      const content = await queryOrderDetail(payload)
      commit('UPDATE_STATE_VALUE', { key: 'orderDetail', data: content })
    },
    async getPaySign({ dispatch, commit }, payload) {
      const { callback, ...rest } = payload;
      const res = await queryPaySign(rest)
      typeof callback === 'function' && callback()
      const { payType, orderCode, price } = rest || {}
      if (res) {
        if (payType === 1) {
          window.open(res.sign, '_blank');
        } else {
          sessionStorage.setItem('codeUrl', res.codeUrl);
          router.push(`/scanTopay?payType=${payType}&price=${price}&orderCode=${orderCode}`);
        }
      }
    },
    async getInvoicesData({ dispatch, commit }, payload) {
      const {checkInvoices,...rest}=payload;
      const content = await queryInvoiceList(rest)
      commit('UPDATE_STATE_VALUE', { key: 'invoiceData', data: content })
      if(checkInvoices){
        commit('UPDATE_STATE_VALUE', { key: 'hasInvoices', data: content.total })

      }
    }
  },
  mutations: {
    UPDATE_STATE_VALUE(state, payload) {
      const { key, data } = payload || {};
      state[key] = data;
    },

  },
};
