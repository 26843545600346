/* eslint-disable */
import { queryCouponList,couponRelateGoods } from '@/services/coupon';
import router from "../../router"
import { ElMessage } from 'element-plus'

export default {
  namespaced: true,
  state: {
    couponList:[],
    couponGoods:[]

  },
  actions: {
    async getCouponList({commit},payload){
      const content =await queryCouponList(payload)
      commit('UPDATE_STATE_VALUE',{key:'couponList',data:content.list||[]})
    },
    async getCouponGoods({commit},payload){
      const { couponId, userCouponId } = payload
      const content =await couponRelateGoods({couponId})
      let list=content.list||[];
      if(list.length===1){
        const { itemCode } = list[0]
        router.push(`/goodsInfo?itemCode=${itemCode}&userCouponId=${userCouponId}`)
      }else if (list.length>1){
        router.push(`/home?userCouponId=${userCouponId}`)
      } else {
        ElMessage.error('该优惠券暂时没有适用商品')
      }
      commit('UPDATE_STATE_VALUE',{key:'couponGoods',data:content.list||[]})
    }

  },
  mutations: {
    UPDATE_STATE_VALUE(state, payload) {
      const { key, data } = payload || {};
      state[key] = data;
    },

  },
};
