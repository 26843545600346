/* eslint-disable */
export const cookieUtils = {
  // 返回键值为key 的本地缓存数据, 如果没有的话, 返回 undefined
  getItem: (sKey) => decodeURIComponent(document.cookie.replace(new RegExp(`(?:(?:^|.*;)\\s*${encodeURIComponent(sKey).replace(/[-.+*]/g, '\\$&')}\\s*\\=\\s*([^;]*).*$)|^.*$`), '$1')) || undefined,
  setItem: (sKey, sValue, vEnd, sPath, sDomain, bSecure) => {
    if (!sKey || /^(?:expires|max-age|path|domain|secure)$/i.test(sKey)) {
      return false;
    }
    let sExpires = '';
    if (vEnd) {
      switch (vEnd.constructor) {
        case Number:
          sExpires = vEnd === Infinity ? '; expires=Fri, 31 Dec 9999 23:59:59 GMT' : `; max-age=${vEnd}`;
          break;
        case String:
          sExpires = `; expires=${vEnd}`;
          break;
        case Date:
          sExpires = `; expires=${vEnd.toUTCString()}`;
          break;
        default:
          return sExpires;
      }
    }
    document.cookie = `${encodeURIComponent(sKey)}=${encodeURIComponent(sValue)}${sExpires}${sDomain ? `; domain=${sDomain}` : ''}${sPath ? `; path=${sPath}` : ''}${bSecure ? '; secure' : ''}`;
    return true;
  },
  removeItem: (sKey, sPath, sDomain) => {
    if (!sKey || !this.hasItem(sKey)) {
      return false;
    }
    document.cookie = `${encodeURIComponent(sKey)}=; expires=Thu, 01 Jan 1970 00:00:00 GMT${sDomain ? `; domain=${sDomain}` : ''}${sPath ? `; path=${sPath}` : ''}`;
    return true;
  },
  hasItem: (sKey) => (new RegExp(`(?:^|;\\s*)${encodeURIComponent(sKey).replace(/[-.+*]/g, '\\$&')}\\s*\\=`)).test(document.cookie),
  keys: () => {
    const allKeys = document.cookie.replace(/((?:^|\s*;)[^=]+)(?=;|$)|^\s*|\s*(?:=[^;]*)?(?:\1|$)/g, '').split(/\s*(?:=[^;]*)?;\s*/);
    for (let i = 0; i < allKeys.length; i++ ) {
      allKeys[i] = decodeURIComponent(allKeys[i]);
    }
    return allKeys;
  },
};

export const localStorageUtils = {
  // 返回键值为key 的本地缓存数据, 如果没有的话, 返回 undefined
  getItem: (key) => {
    const result = localStorage.getItem(key);
    if (typeof result === 'string' && result!=='undefined' && !!result) {
      return JSON.parse(result);
    }
    return null;
  },
  setItem: (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  },
  removeItem: (key) => {
    localStorage.removeItem(key);
  },
};
